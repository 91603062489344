import { Box } from '@chakra-ui/react'
import { ContentstackArticleCard as ContentstackArticleCardProps } from '@Types/contentstack'
import { ArticleCard } from '../cms-components/article-card'
import { imageOptimization } from '../general/components/image-optimization'

export const ContentstackArticleCard = ({
  eyebrow,
  article_card_title,
  content,
  url,
  text_align,
  image,
  article_card_title_font_size,
  article_card_title_font_family,
  analyticsTrackingData,
  relativeIndex,
  text_decoration,
  isCertonaItem,
  pID,
  priceInfo,
  certonaListType,
}: ContentstackArticleCardProps) => {
  return (
    <ArticleCard
      image={{
        src: imageOptimization(image?.url) ?? '',
        alt: image?.description || image?.title || article_card_title,
      }}
      eyebrow={{
        children: eyebrow ?? '',
      }}
      title={{
        font: {
          size: article_card_title_font_size ?? '',
          family: article_card_title_font_family ?? '',
        },
        children: article_card_title ?? '',
      }}
      description={{
        children: content ? <Box dangerouslySetInnerHTML={{ __html: content }} /> : undefined,
      }}
      href={url ?? ''}
      textAlign={text_align as any}
      analyticsTrackingData={analyticsTrackingData}
      relativeIndex={relativeIndex}
      textDecoration={text_decoration}
      isCertonaItem={isCertonaItem}
      pID={pID}
      pPriceInfo={priceInfo}
      certonaListType={certonaListType}
    />
  )
}
