import { forwardRef } from 'react'
import { Box, BoxProps, Flex, FlexProps, IconButton, IconButtonProps } from '@chakra-ui/react'
import { KeenSliderOptions, useKeenSlider } from 'keen-slider/react'

export const Carousel = forwardRef<HTMLDivElement, FlexProps>(function Carousel(props, ref) {
  return (
    <Flex ref={ref} className="chakra-carousel" overflow="hidden" position="relative" userSelect="none" {...props} />
  )
})

export const CarouselSlide = (props: BoxProps) => (
  <Box
    className="chakra-carousel__slide"
    position="relative"
    overflow="hidden"
    minWidth="100%"
    maxWidth="100%"
    minH="100%"
    {...props}
  />
)

export const CarouselIconButton = (props: IconButtonProps) => (
  <IconButton
    variant="unstyled"
    boxSize="auto"
    minW="auto"
    display="inline-flex"
    fontSize="2xl"
    color="gray.600"
    _hover={{
      backgroundColor: 'shading.200',
      _disabled: { color: 'gray.600' },
    }}
    _active={{ color: 'primary' }}
    _focus={{ boxShadow: 'none' }}
    _focusVisible={{ boxShadow: 'outline' }}
    {...props}
  />
)

export const useCarousel = (options?: KeenSliderOptions): any => {
  const defaultOptions = { selector: '.chakra-carousel__slide' }
  return useKeenSlider<HTMLDivElement>({ ...defaultOptions, ...options })
}
